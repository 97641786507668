import { bitfinityTestnetTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    lpAddress: '0x01f324ef8800A78b4cFF5c3ad128e99A31d1aaF3',
    token0: bitfinityTestnetTokens.weth,
    token1: bitfinityTestnetTokens.mock,
    feeAmount: FeeAmount.MEDIUM,
  },
])
