import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | dexlyn',
  defaultTitle: 'dexlyn',
  description: 'Trade, earn, and own crypto on the all-in-one multichain DEX',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@dexlyn',
    site: '@dexlyn',
  },
  openGraph: {
    title: "🥞 dexlyn - Everyone's Favorite DEX",
    description: 'Trade, earn, and own crypto on the all-in-one multichain DEX',
    images: [{ url: '/thumbnail.png' }],
  },
}
