import { HelpIcon } from '@pancakeswap/uikit'
import Image from 'next/image'
import { memo, useState } from 'react'
import { isChainSupported } from 'utils/wagmi'

export const ChainLogo = memo(
  ({ chainId, width = 24, height = 24 }: { chainId?: number; width?: number; height?: number }) => {
    const [src, setSrc] = useState(`/networks/${chainId}.png`)
    const defaultImage = `/networks/${chainId}.png`

    const handleError = () => {
      setSrc(defaultImage)
    }
    if (chainId && isChainSupported(chainId)) {
      return (
        <Image
          alt={`chain-${chainId}`}
          style={{ maxHeight: `${height}px`, borderRadius: 1000 }}
          src={src}
          width={width}
          height={height}
          onError={handleError}
          unoptimized
        />
      )
    }

    return <HelpIcon width={width} height={height} />
  },
)
