import { zkSyncSepoliaTestnetTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    lpAddress: '0x019F6c0BDe81a71613EAC2aD00cc1ACfc93fe102',
    token0: zkSyncSepoliaTestnetTokens.weth,
    token1: zkSyncSepoliaTestnetTokens.mock,
    feeAmount: FeeAmount.LOW,
  },
])
