import { fireTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    lpAddress: '0x21A811d3f1344e5C3a075af0CD5b320Bdff7D6eC',
    token0: fireTokens.weth,
    token1: fireTokens.mock,
    feeAmount: FeeAmount.MEDIUM,
  },
])
