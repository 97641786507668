import { Language } from "../LangSelector/types";
import { GithubIcon, TelegramIcon, TwitterIcon } from "../Svg";
import { FooterLinkType } from "./types";

export const footerLinks: FooterLinkType[] = [
  {
    label: "About",
    items: [
      {
        label: "Contact",
        href: "https://docs.dexlyn.xyz/contact-us",
      },
      {
        label: "Blog",
        href: "https://blog.dexlyn-swap.finance/",
      },
      {
        label: "Community",
        href: "https://docs.dexlyn.xyz/contact-us/telegram",
      },
      {
        label: "CAKE",
        href: "https://docs.dexlyn.xyz/tokenomics/cake",
      },
      {
        label: "—",
      },
      {
        label: "Online Store",
        href: "https://dexlyn-swap.creator-spring.com/",
        isHighlighted: true,
      },
    ],
  },
  {
    label: "Help",
    items: [
      {
        label: "Customer",
        href: "Support https://docs.dexlyn.xyz/contact-us/customer-support",
      },
      {
        label: "Troubleshooting",
        href: "https://docs.dexlyn.xyz//help/troubleshooting",
      },
      {
        label: "Guides",
        href: "https://docs.dexlyn.xyz/get-started",
      },
    ],
  },
  {
    label: "Developers",
    items: [
      {
        label: "Github",
        href: "https://github.com/dexlyn-swap",
      },
      {
        label: "Gitbook",
        href: "https://dexlyn.gitbook.io/dexlyn",
      },
      {
        label: "Documentation",
        href: "https://docs.dexlyn.xyz/",
      },
      {
        label: "Bug Bounty",
        href: "https://app.gitbook.com/@dexlyn-swap-1/s/dexlyn-swap/code/bug-bounty",
      },
      {
        label: "Audits",
        href: "https://dexlyn.gitbook.io/dexlyn/general/faqs",
      },
      {
        label: "Careers",
        href: "https://dexlyn.gitbook.io/dexlyn/governance/how-to-participate-in-dexlyn-governance",
      },
    ],
  },
];

export const socials = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://x.com/dexlynmove",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    href: "https://t.me/dexlynxyz",
  },
  // {
  //   label: "Telegram",
  //   icon: TelegramIcon,
  //   items: [
  //     {
  //       label: "English",
  //       href: "https://t.me/dexlyn-swap",
  //     },
  //     {
  //       label: "Bahasa Indonesia",
  //       href: "https://t.me/pancakeswapIndonesia",
  //     },
  //     {
  //       label: "中文",
  //       href: "https://t.me/pancakeswap_CN",
  //     },
  //     {
  //       label: "Tiếng Việt",
  //       href: "https://t.me/PancakeSwapVN",
  //     },
  //     {
  //       label: "Italiano",
  //       href: "https://t.me/pancakeswap_Ita",
  //     },
  //     {
  //       label: "русский",
  //       href: "https://t.me/pancakeswap_ru",
  //     },
  //     {
  //       label: "Türkiye",
  //       href: "https://t.me/pancakeswapturkiye",
  //     },
  //     {
  //       label: "Português",
  //       href: "https://t.me/pancakeswapPortuguese",
  //     },
  //     {
  //       label: "Español",
  //       href: "https://t.me/pancakeswapES",
  //     },
  //     {
  //       label: "日本語",
  //       href: "https://t.me/pancakeswapJP",
  //     },
  //     {
  //       label: "Français",
  //       href: "https://t.me/pancakeswapFR",
  //     },
  //     {
  //       label: "Deutsch",
  //       href: "https://t.me/pancakeswap_DE",
  //     },
  //     {
  //       label: "Filipino",
  //       href: "https://t.me/pancakeswap_PH",
  //     },
  //     {
  //       label: "ქართული ენა",
  //       href: "https://t.me/pancakeswapGeorgia",
  //     },
  //     {
  //       label: "हिन्दी",
  //       href: "https://t.me/pancakeswap_INDIA",
  //     },
  //     {
  //       label: "Announcements",
  //       href: "https://t.me/PancakeSwapAnn",
  //     },
  //   ],
  // },
  // {
  //   label: "Reddit",
  //   icon: RedditIcon,
  //   href: "https://reddit.com/r/dexlyn-swap",
  // },
  // {
  //   label: "Instagram",
  //   icon: InstagramIcon,
  //   href: "https://instagram.com/pancakeswap_official",
  // },
  {
    label: "Github",
    icon: GithubIcon,
    href: "https://docs.dexlyn.xyz/",
  },

  // {
  //   label: "Youtube",
  //   icon: YoutubeIcon,
  //   href: "https://www.youtube.com/@pancakeswap_official",
  // },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
