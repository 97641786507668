import { movementTestnetTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    lpAddress: '0x8c7e1CA41907A0b41fB79DEC8e2798A669851842',
    token0: movementTestnetTokens.wmove,
    token1: movementTestnetTokens.mock,
    feeAmount: FeeAmount.MEDIUM,
  },
])
