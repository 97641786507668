import { useIsMounted } from "@pancakeswap/hooks";
import React from "react";
import styled from "styled-components";
import { useMatchBreakpoints } from "../../contexts";
import { Flex } from "../Box";
import { StyledFooter, StyledSocialLinks } from "./styles";

import { FooterProps } from "./types";

const DesFooterWrap = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.001em;
  color: rgba(255, 255, 255, 0.6);
`;

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  chainId,
  ...props
}) => {
  const isMounted = useIsMounted();
  const { isXl } = useMatchBreakpoints();
  return (
    <StyledFooter
      data-theme="dark"
      // p={["12px 16px", null, "56px 40px 65px 40px"]}
      position="relative"
      {...props}
      justifyContent="center"
    >
      <Flex
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        alignItems={["center", "center"]}
        width={["100%", null, "1200px;"]}
      >
        <StyledSocialLinks order={[2]} pb={["0", null, "0"]} mb={["0", null, "0"]} />
        <DesFooterWrap>Build with love DeFi @ Dexlyn X MovementLabs</DesFooterWrap>
      </Flex>
    </StyledFooter>
  );
};

export default MenuItem;
